<template>
  <!-- 版本升级 -->
  <div class="box-card">
    <!-- 面包屑导航 -->
    <div class="card_header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>运维中心</el-breadcrumb-item>
        <el-breadcrumb-item>版本管理</el-breadcrumb-item>
        <el-breadcrumb-item>版本升级</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="right">
        <lbButton icon="xinzeng" @click="$router.push('/update-version-grade')">升级</lbButton>
      </div>
    </div>
    <!-- 主要内容 -->
    <div style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" @submit.native.prevent>
        <el-form-item label="序列号：">
          <el-input v-model="queryForm.device_serial" maxlength="30" @keyup.enter.native="onSubmit" @input="(e) => (queryForm.device_serial = validFacility(e))" placeholder="请输入序列号"></el-input>
        </el-form-item>
        <el-form-item label="设备名称：">
          <el-input v-model="queryForm.device_name" maxlength="30" @keyup.enter.native="onSubmit" @input="(e) => (queryForm.device_name = validSpace(e))" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select popper-class="my-select" v-model="queryForm.upgrade_status" placeholder="请选择状态" clearable @change="onSubmit()">
            <el-option v-for="item in getOptions('UpgradeStatus')" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
        <el-table-column prop="device_serial" label="序列号"></el-table-column>
        <el-table-column prop="device_name" label="设备名称"></el-table-column>
        <el-table-column prop="old_version_no" label="旧版本"></el-table-column>
        <el-table-column prop="new_version_no" label="新版本"></el-table-column>
        <el-table-column prop="create_name" label="操作人"></el-table-column>
        <el-table-column label="升级时间" width="180">
          <template slot-scope="scope">
            {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="150">
          <template slot-scope="scope">
            <el-link type="primary" v-if=" scope.row.upgrade_status == 4" @click="openDetailDialog(scope.row)">{{ getWayName("UpgradeStatus",  scope.row.upgrade_status)}}</el-link>
            <span v-else>{{ getWayName("UpgradeStatus",  scope.row.upgrade_status)}}</span>
            <!-- <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(scope.row)" v-if="isShowBtn('63759829177400006e002e08')"></lbButton>
            <lbButton type="succes" icon="download" hint="下载" @click="downloadHandle(scope.row.version_addr)" v-if="isShowBtn('63759829177400006e002e0a')"></lbButton> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" >
      </el-pagination>
    </div>
    <el-dialog :close-on-click-modal="false" append-to-body title="升级失败" :visible.sync="isDetailDialog" width="35%">
      <span>原因详情：{{detalForm.fail_reason}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitAddForm()" v-preventReClick>重新升级</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBrandList,
  getModelList,
} from "../../../api/deviceApi";
import { getVersionUpgradeRecordPageList, setVersionUpgrade } from "../../../api/maintenanceCenter";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        device_serial: "",
        device_name: "",
        upgrade_status: null,
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 新增对话框
      isDetailDialog: false,
      detalForm: {},
      // 设备品牌下拉框
      brandList: [],
      // 设备型号下拉框
      modelList: [],
      // videopreview: false,
      // videopreviewurl: "",
      // videopreview_id: "",
      // loading: false,
    };
  },
  created() {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj);
    }
    this.fnGetVersionUpgradeRecordPageList();
  },
  beforeRouteLeave(to, from, next) {
    this.setQueryObj(this.queryForm);
    this.setQueryStatus(1);
    next();
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
  computed: {
    ...mapState(["queryObj", "queryStatus"]),
    ...mapGetters(["isAdmin", "getOptions", "getWayName"]),
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
  methods: {
    ...mapMutations(["setQueryObj", "setQueryStatus"]),
    beforeunloadFn(e) {
      this.setQueryStatus(1);
    },
    // 获取数据列表
    async fnGetVersionUpgradeRecordPageList() {
      const res = await getVersionUpgradeRecordPageList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        device_serial: this.queryForm.device_serial,
        device_name: this.queryForm.device_name,
        upgrade_status: this.queryForm.upgrade_status ? this.queryForm.upgrade_status : null,
      });
      this.total = res && res.Code === 200 ? res.Data.TotalCount : 0;
      this.tableData = res && res.Code === 200 ? res.Data.DataList : [];
    },
    // 查询
    onSubmit() {
      this.queryForm.PageIndex = 1;
      // console.log(this.queryForm);
      this.fnGetVersionUpgradeRecordPageList();
    },
    // 每页条数改变
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val;
      this.fnGetVersionUpgradeRecordPageList();
    },
    // 页码改变
    handleCurrentChange(val) {
      this.queryForm.PageIndex = val;
      this.fnGetVersionUpgradeRecordPageList();
      // console.log(`当前页: ${val}`);
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    // 查看失败原因
    openDetailDialog(item) {
      console.log(item);
      this.detalForm = item;
      this.isDetailDialog = true;
    },
    async submitAddForm() {
      const res = await setVersionUpgrade({
        device_version_id: this.detalForm.device_version_id,
        device_version_no: this.detalForm.new_version_no,
        device_version_addr: this.detalForm.device_version_addr,
        devices: [
          {
            device_serial: this.detalForm.device_serial,
            device_name: this.detalForm.device_name,
            device_version: this.detalForm.old_version_no
          }
        ]
      });
      if (res && res.Code === 200) {
        this.isDetailDialog = false;
      }
    }
  },
  watch: {
  },
};
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}
</style>
