/**
 *  设备管理相关请求
 */
import axios from './http';

// 地磁设备列表
export const getGeomagnetismList = (params) => axios.get('/ParkingLotApi/Geomagnetism/GetPageList', { params: params })
//  删除地磁设备
export const deleteGeomagnetism = (data) => axios.post('/ParkingLotApi/Geomagnetism/Delete', data)
// 新增地磁设备
export const addGeomagnetism = (data) => axios.post('/ParkingLotApi/Geomagnetism/Add', data)
// 编辑地磁设备
export const updateGeomagnetism = (data) => axios.post('/ParkingLotApi/Geomagnetism/Update', data)
// 地磁详情/编辑地磁界面
export const getGeomagnetismDetails = (params) => axios.get('/ParkingLotApi/Geomagnetism/GetDetails', { params: params })

// 高位相机列表
export const getHighCameraList = (params) => axios.get('/ParkingLotApi/HighCamera/GetPageList', { params: params })
// 删除高位相机
export const delHighCamera = (data) => axios.post('/ParkingLotApi/HighCamera/Delete', data)
// 新增高位相机
export const addHighCamera = (data) => axios.post('/ParkingLotApi/HighCamera/Add', data)
// 编辑高位相机
export const updateHighCamera = (data) => axios.post('/ParkingLotApi/HighCamera/Update', data)
// 高位相机详情/编辑高位相机界面
export const getHighCameraDetails = (params) => axios.get('/ParkingLotApi/HighCamera/GetDetails', { params: params })

// 视频桩列表接口
export const getVideoPileList = (params) => axios.get('/ParkingLotApi/VideoPile/GetPageList', { params: params })
// 删除视频桩
export const delVideoPile = (data) => axios.post('/ParkingLotApi/VideoPile/Delete', data)
// 新增视频桩
export const addVideoPile = (data) => axios.post('/ParkingLotApi/VideoPile/Add', data)
// 编辑视频桩
export const updateVideoPile = (data) => axios.post('/ParkingLotApi/VideoPile/Update', data)
// 视频桩详情/编辑视频桩界面
export const getVideoPileDetails = (params) => axios.get('/ParkingLotApi/VideoPile/GetDetails', { params: params })
// 视频预览接口-打开
export const getliveOpenUrl = (params) => axios.get('/ParkingLotApi/HighCamera/LiveOpen', { params: params })
// 视频预览接口-关闭
export const getliveCloseUrl = (params) => axios.get('/ParkingLotApi/HighCamera/LiveClose', { params: params })

// 路牙机设备列表接口
export const getDeviceMachineList = (params) => axios.get('/ParkingLotApi/DeviceMachine/GetPageList', { params: params })
// 新增路牙机接口
export const addDeviceMachine = (data) => axios.post('/ParkingLotApi/DeviceMachine/Add', data)
// 编辑路牙机设备接口
export const updateDeviceMachine = (data) => axios.post('/ParkingLotApi/DeviceMachine/Update', data)
// 删除路牙机接口
export const delDeviceMachine = (data) => axios.post('/ParkingLotApi/DeviceMachine/Delete', data)
// 路牙机详情/编辑路牙机界面接口
export const getDeviceMachineDetails = (params) => axios.get('/ParkingLotApi/DeviceMachine/GetDetails', { params: params })

// 车牌识别相机设备列表
export const getIdentifyCameraList = (params) => axios.get('/ParkingLotApi/IdentifyCamera/GetPageList', { params: params })
// 新增车牌识别相机
export const addIdentifyCamera = (data) => axios.post('/ParkingLotApi/IdentifyCamera/Add', data)
// 编辑车牌识别相机
export const updateIdentifyCamera = (data) => axios.post('/ParkingLotApi/IdentifyCamera/Update', data)
// 删除车牌识别相机
export const delIdentifyCamera = (data) => axios.post('/ParkingLotApi/IdentifyCamera/Delete', data)
// 车牌识别相机详情
export const getIdentifyCameraDetails = (params) => axios.get('/ParkingLotApi/IdentifyCamera/GetDetails', { params: params })

// 查询显示屏设备列表
export const getDisplayDeviceList = (params) => axios.get('/ParkingLotApi/DisplayDevice/GetPageList', { params: params })
// 新增显示屏
export const addDisplayDevice = (data) => axios.post('/ParkingLotApi/DisplayDevice/Add', data)
// 编辑显示屏
export const updateDisplayDevice = (data) => axios.post('/ParkingLotApi/DisplayDevice/Update', data)
// 删除显示屏
export const delDisplayDevice = (data) => axios.post('/ParkingLotApi/DisplayDevice/Delete', data)
// 显示屏详情
export const getDisplayDeviceDetails = (params) => axios.get('/ParkingLotApi/DisplayDevice/GetDetails', { params: params })
// 显示屏配置
export const addorUptDisplayDeviceConfig = (data) => axios.post('/ParkingLotApi/DisplayDevice/AddorUptDisplayDeviceConfig', data)
// 获取显示屏配置详情
export const getDisplayDeviceConfigDetail = (params) => axios.get('/ParkingLotApi/DisplayDevice/GetDisplayDeviceConfig', { params: params })
// 获取车牌识别相机下拉列表（用于配置显示屏）
export const getIdentifyCameralsList = (params) => axios.get('/ParkingLotApi/DisplayDevice/GetIdentifyCameralsTest', { params: params })

// 查询一体机设备列表
export const getIntegratedMachineList = (params) => axios.get('/ParkingLotApi/IntegratedMachine/GetPageList', { params: params })
// 新增一体机
export const addIntegratedMachine = (data) => axios.post('/ParkingLotApi/IntegratedMachine/Add', data)
// 编辑一体机
export const updateIntegratedMachine = (data) => axios.post('/ParkingLotApi/IntegratedMachine/Update', data)
// 删除一体机
export const delIntegratedMachine = (data) => axios.post('/ParkingLotApi/IntegratedMachine/Delete', data)
// 一体机详情
export const getIntegratedMachineDetails = (params) => axios.get('/ParkingLotApi/IntegratedMachine/GetDetails', { params: params })

// 设备品牌下拉框
export const getBrandList = (params) => axios.get('/ParkingLotApi/Geomagnetism/GetBrandList', { params: params })
// 设备型号下拉框
export const getModelList = (params) => axios.get('/ParkingLotApi/Geomagnetism/GetModelList', { params: params })
