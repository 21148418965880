/**
 *  运维中心相关请求
 */
import axios from './http'

// 开发者 = 1,  小助手 = 2,  用户协议 = 3,  隐私政策 = 4,  咨询热线 = 5,  公司官网 = 6

//  获取帮助文档数据结构
export const getMaintenanceMain = (params) => axios.get('/MaintenanceApi/MaintenanceMain/GetTreeList', { params: params })
// 新增帮助文档数据
export const addMaintenanceMain = (data) => axios.post('/MaintenanceApi/MaintenanceMain/add', data)
// 修改帮助文档数据
export const updateMaintenanceMain = (data) => axios.post('/MaintenanceApi/MaintenanceMain/update', data)
// 获取帮助文档详情
export const getMaintenanceMainDetail = (params) => axios.get('/MaintenanceApi/MaintenanceMain/GetDetail', { params: params })
// 删除帮助文档
export const delMaintenanceMain = (data) => axios.post('/MaintenanceApi/MaintenanceMain/delete', data)
// 获取下拉列表
export const getSelectList = (params) => axios.get('/MaintenanceApi/MaintenanceMain/GetSelectList', { params: params })
// 查询意见反馈
export const getFeedBackPageList = (params) => axios.get('/MaintenanceApi/MaintenanceMain/GetPageList', { params: params })
// 删除意见反馈
export const delFeedBack = (data) => axios.post('/MaintenanceApi/MaintenanceMain/deletebyfeed', data)

// 获取设备版本列表
export const getVersionlist = (params) => axios.get('/MaintenanceApi/VersionManage/GetPageList', { params: params })
// 获取设备版本详情
export const getVersionDetail = (params) => axios.get('/MaintenanceApi/VersionManage/GetDetail', { params: params })
// 新增设备版本
export const addVersion = (data) => axios.post('/MaintenanceApi/VersionManage/Add', data)
// 修改设备版本
export const updateVersion = (data) => axios.post('/MaintenanceApi/VersionManage/Update', data)
// 删除设备版本
export const delVersion = (data) => axios.post('/MaintenanceApi/VersionManage/Delete', data)
// 获取设备版本下拉框列表
export const getVersionSelectList = (params) => axios.get('/MaintenanceApi/VersionManage/GetSelectList', { params: params })
// 获取版本升级记录列表
export const getVersionUpgradeRecordPageList = (params) => axios.get('/MaintenanceApi/UpgradeRecord/GetPageList', { params: params })
// 根据品牌型号获取设备列表
export const getDevicelsByModelList = (params) => axios.get('/MaintenanceApi/UpgradeRecord/GetDevicelsByModel', { params: params })
// 版本升级
export const setVersionUpgrade = (data) => axios.post('/MaintenanceApi/UpgradeRecord/VersionUpgrade', data)
// 获取手机端下拉列表
export const getPhoneCilentList = (params) => axios.get('/MaintenanceApi/MaintenanceMain/GetPhoneCilentList', { params: params })
// 获取TCP服务消费监测分页列表
export const getTcpServiceMessage = (params) => axios.get('/MaintenanceApi/TcpServiceMessage/GetPageList', { params: params })
// 获取TCP服务异常分页列表
export const getTcpExceptionPageList = (params) => axios.get('/MaintenanceApi/TcpServiceMessage/GetExceptionPageList', { params: params })
// 获取AI问答分页列表
export const getAiQuestionsAnswers = (params) => axios.get('/MaintenanceApi/AiQuestionsAnswers/GetPageList', { params: params })
// 批量删除AI问答
export const deletAiQuestionsAnswers = (data) => axios.post('/MaintenanceApi/AiQuestionsAnswers/Delete', data)
// 新增AI问答
export const addAiQuestionsAnswers = (data) => axios.post('/MaintenanceApi/AiQuestionsAnswers/Add', data)
// 编辑AI问答
export const updateAiQuestionsAnswers = (data) => axios.post('/MaintenanceApi/AiQuestionsAnswers/Update', data)
// 获取AI问答详情
export const getAiQuestionsAnswersDetails = (params) => axios.get('/MaintenanceApi/AiQuestionsAnswers/GetDetail', { params: params })
// 编辑AI问答状态
export const UpdateAiQuestionsActive = (data) => axios.post('/MaintenanceApi/AiQuestionsAnswers/UpdateActive', data)
// 获取体验用户列表
export const getAIAccountInfoList = (params) => axios.get('/MaintenanceApi/AIAccountInfo/GetPageList', { params: params })
// 批量删除体验用户
export const deletAIAccount = (data) => axios.post('/MaintenanceApi/AIAccountInfo/Delete', data)
// 新增ai体验用户
export const addAIExperienceCounter = (data) => axios.post('/MaintenanceApi/AIAccountInfo/Add', data)
// 编辑ai体验用户
export const updateAIExperienceCounter = (data) => axios.post('/MaintenanceApi/AIAccountInfo/Update', data)
// 获取体验用户详情
export const getAIAccountInfoDetial = (params) => axios.get('/MaintenanceApi/AIAccountInfo/GetDetail', { params: params })
// 获取AI账号审核分页列表
export const getAIAccountCheckList = (params) => axios.get('/MaintenanceApi/AIAccountAudit/GetPageList', { params: params })
// 获取AI账号审核详情
export const getAIAccountCheckDetail = (params) => axios.get('/MaintenanceApi/AIAccountAudit/GetDetail', { params: params })
// 审核AI账号
export const checkAICounter = (data) => axios.post('/MaintenanceApi/AIAccountAudit/Audit', data)
// 获取问答记录分页列表
export const getAIChatPageList = (params) => axios.get('/MaintenanceApi/AIChattingRecords/GetPageList', { params: params })
// 获取聊天记录分页列表
export const getAITalkPageList = (params) => axios.get('/MaintenanceApi/AIChattingRecords/GetChatRecordList', { params: params })
// 获取个人具体聊天记录
export const getPersonAITalkPageList = (params) => axios.get('/MaintenanceApi/AIChattingRecords/GetOwnerChatList', { params: params })
// 获取获取客户端版本管理详情
export const getClientVersion = (params) => axios.get('/MaintenanceApi/VersionManagement/GetPageList', { params: params })
// 更改版本管理启用状态
export const changeVersionManagementStatus = (data) => axios.post('/MaintenanceApi/VersionManagement/UpdateIsStart', data)
// 获取客户端版本管理详情
export const getVersionManagementDetail = (params) => axios.get('/MaintenanceApi/VersionManagement/GetDetail', { params: params })
// 新增客户端版本管理
export const addVersionManagement = (data) => axios.post('/MaintenanceApi/VersionManagement/Add', data)
// 编辑客户端版本管理
export const updateVersionManagement = (data) => axios.post('/MaintenanceApi/VersionManagement/Update', data)
// 删除客户端版本管理
export const deleteVersionManagement = (data) => axios.post('/MaintenanceApi/VersionManagement/Delete', data)
// 上传文件
export const uploadVersionFile = (data) => {
  return axios({
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data'
    },
    contentType: 'multipart/form-data',
    url: '/FileUploadServiceApi/file/UploadFile',
    data
  })
}
// 是否自动审核
export const getAutoReview = (params) => axios.get('/MaintenanceApi/AIAccountAudit/GetActiveDetail', { params: params })
// 开启自动审核
export const autoReview = (data) => axios.post('/MaintenanceApi/AIAccountAudit/UpdateActive', data)
